import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, IconButton, Typography } from '@material-ui/core';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import FilterIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { makeStyles } from "@material-ui/styles";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
} from 'date-fns';
import { endOfYear } from "date-fns";
import { SidebarGeneral, SidebarRight } from "layouts/Main/components";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  startOfHalfYear: startOfMonth(addMonths(new Date(), -5)),
  endOfHalfYear: endOfQuarter(new Date()),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
};


const staticRanges = [
  ...createStaticRanges([
    // {
    //   label: 'Today',
    //   range: () => ({
    //     startDate: defineds.startOfToday,
    //     endDate: defineds.endOfToday,
    //   }),
    // },
    // {
    //   label: 'Yesterday',
    //   range: () => ({
    //     startDate: defineds.startOfYesterday,
    //     endDate: defineds.endOfYesterday,
    //   }),
    // },

    // {
    //   label: 'This Week',
    //   range: () => ({
    //     startDate: defineds.startOfWeek,
    //     endDate: defineds.endOfWeek,
    //   }),
    // },
    // {
    //   label: 'Last Week',
    //   range: () => ({
    //     startDate: defineds.startOfLastWeek,
    //     endDate: defineds.endOfLastWeek,
    //   }),
    // },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: 'This Quarter',
      range: () => ({
        startDate: defineds.startOfQuarter,
        endDate: defineds.endOfQuarter,
      }),
    },
    {
      label: 'This Half Year',
      range: () => ({
        startDate: defineds.startOfHalfYear,
        endDate: defineds.endOfHalfYear,
      }),
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    },

    // {
    //   label: 'Last Month',
    //   range: () => ({
    //     startDate: defineds.startOfLastMonth,
    //     endDate: defineds.endOfLastMonth,
    //   }),
    // },
  ]),
  // ...defaultStaticRanges,
];



const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const defaultState = [{
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}]

function ZnDateRange({ title, name, onChange, defaultStartDate, defaultEndDate, slim }) {


  const classes = useStyles();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    setStartDate(defaultStartDate)
  }, [defaultStartDate])

  useEffect(() => {
    setEndDate(defaultEndDate)
  }, [defaultEndDate])


  useEffect(() => {
    onChange && onChange({
      target: {
        name: name,
        value: {
          startDate: startDate,
          endDate: endDate,
        }

      }

    });
  }, [startDate, endDate]);


  const [open, setOpen] = useState(false);

  const [state, setState] = useState(defaultState)


  const onRangeChange = (item) => {
    setState([item.selection])
  }

  const onClickApply = () => {
    setStartDate(state[0].startDate);
    setEndDate(state[0].endDate);
    setOpen(false);
  }

  const onClickClear = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setState(defaultState)
    setOpen(false);
  }

  const onClickClose = () => {
    setOpen(false);
  }



  return (
    <Fragment>
      <Button onClick={event => setOpen(true)}>
        {!slim && (
          <IconButton >
            <FilterIcon />
          </IconButton>
        )}
        <Typography>
          <>
            {startDate || endDate ?
              <>
                {slim ? "From " : ''}{startDate && moment(startDate).format('ll')}
                {slim ? ' - ' : ' to '} {endDate && moment(endDate).format('ll')}
              </> :
              <>
                {title ? title : 'Choose date range'}
              </>}
          </>
        </Typography>
      </Button>
      <SidebarGeneral > 
        <DateRangePicker
            onChange={onRangeChange}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            
            direction="vertical"
            maxDate={new Date()}
            displayMode="dateRange"
            // showMonthAndYearPickers={false}
            // showDateDisplay={false}
            // showMonthArrow={false}
            // showPreview={false}
            // showSelectionPreview={false}
            inputRanges={[]}
            staticRanges={staticRanges}
          /></SidebarGeneral>
      {/* <Dialog open={open} fullWidth={true} maxWidth="md" >
        <DialogTitle>
          {title ? title : 'Choose date range'}
          <IconButton className={classes.closeButton} onClick={event => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DateRangePicker
            onChange={onRangeChange}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            maxDate={new Date()}
            displayMode="dateRange"
            // showMonthAndYearPickers={false}
            // showDateDisplay={false}
            // showMonthArrow={false}
            // showPreview={false}
            // showSelectionPreview={false}
            inputRanges={[]}
            staticRanges={staticRanges}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickClose}>Close</Button>
          <Button onClick={onClickClear}>Clear</Button>
          <Button onClick={onClickApply}>Apply</Button>
        </DialogActions>
      </Dialog> */}
    </Fragment>
  );
}

ZnDateRange.defaultProps = {
  onChange: () => { },

}


export default ZnDateRange;


