import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, TextField, IconButton, Button, LinearProgress } from '@material-ui/core';
import { ZnDepartmentSelect } from 'common/znFields/ZnDepartmentSelect';
import { ZnSourceSelect } from 'common/znFields/ZnSourceSelect';
import { ZnButton } from 'common/znFields/ZnButton';
import { SidebarGeneral } from 'layouts/Main/components';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear, subMonths, subQuarters } from 'date-fns';
import { format } from 'date-fns';
import { FilterList } from '@material-ui/icons';
import { source } from '_reducers/source.reducer';
import TotalRadial from '../TotalRadial';
import Typography from 'theme/typography';
import { useSelector } from 'react-redux';


const Recap = (props) => {


  const {
    loading,
    received,
    spam,
    autospam,
    managed,
    converted,
    received_perc,
    spam_perc,
    autospam_perc,
    managed_perc,
    converted_perc, } = props;

  // const cartData = useSelector(state => state.analytics.cart2)

  //   const [loadeds, setLoadeds] = useState();
  // useEffect(()=> {

  //   // if(!totals) return;
  //   const lv = {};
  //   lv.received = totals?.received ?? null;
  //   lv.spam = totals?.spam ?? null;
  //   lv.managed = totals?.managed ?? null;
  //   lv.converted = totals?.converted ?? null;
  //   lv.received_perc = totals?.received_perc ?? null;
  //   lv.spam_perc = totals?.spam_perc ?? null;
  //   lv.managed_perc = totals?.managed_perc ?? null;
  //   lv.converted_perc = totals?.converted_perc ?? null;

  //   setLoadeds(lv);


  // }, [totals?.received,totals.managed,totals.spam,totals.converted ])

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    console.log('setLoaded', loading)
    if (loading === false) {
      setLoaded(true);
    }
  }, [loading]);



  return (
    <>
      <Grid
        container
        spacing={4}
      >
        {/* loaded: {JSON.stringify(loaded)} */}
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <TotalRadial loading={loading} loaded={loaded} value={received} percent={received_perc} color="#CCCCCC" title="Received"></TotalRadial>
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={12}>

          <Grid
            container
            spacing={4}
          >
            <Grid item lg={6} md={6} xl={6} xs={12}>
              <TotalRadial loading={loading} loaded={loaded} value={spam} percent={spam_perc} color="#BC2957" title="Spam"></TotalRadial>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12}>
              <TotalRadial loading={loading} loaded={loaded} value={autospam} percent={autospam_perc} color="#BC2957" title="Not eligible"></TotalRadial>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12}>
              <TotalRadial loading={loading} loaded={loaded} value={managed} percent={managed_perc} color="#b9bc29" title="Managed"></TotalRadial>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12}>
              <TotalRadial loading={loading} loaded={loaded} value={converted} percent={converted_perc} color="#29bc37" title="Converted"></TotalRadial>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// Filter.propTypes = {
//   className: PropTypes.string
// };

export default Recap;
