/* eslint-disable no-use-before-define */
import React, { Fragment } from 'react';
import ZnSelect from './ZnSelect';
import { useState } from 'react';
import { useEffect } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';

export {
  ZnCrmSourceSelect as default,
  ZnCrmSourceValue,

}

const defaultCurrentItem = [""]


const ZnCrmSourceSelect = (props) => {

  const {
    name,
    onChange,
    value,
  } = props;


  const items = useSelector(state => state.tags.items);
  const [currentItem, setCurrentItem] = useState(defaultCurrentItem);

  

  const handleChange = event => {
    setCurrentItem(event.target.value);
  };

  useEffect(()=> {
    if (value === "") {
      setCurrentItem(defaultCurrentItem)
    }
  }, [value])

  useEffect(() => {
    onChange && onChange({
      target: {
        name: name,
        value: currentItem
      }
    });
  }, [currentItem]);


  return (
    <Select
      style={{ marginLeft: '10px' }}
      name={name}
      value={currentItem}
      onChange={handleChange}
      displayEmpty
    >
      <MenuItem disabled value="">Sources:</MenuItem>

      {crmSources().map((v, k) => (
        <MenuItem value={v.id}>{v.name}</MenuItem>
      ))}

    </Select>
  );

}

const ZnCrmSourceValue = (props) => {

  const { value } = props;

  const [label, setLabel] = useState(null);

  useEffect(() => {
    const code = crmSources().filter(item => { return item && item.code === value }).shift();
    if (code && code.label) setLabel(code.label);
  }, [value]);

  return (
    <Fragment>{label}</Fragment>
  );
}

const crmSources = () => [

  { id: 'IMPORT', name: 'IMPORT' },
  { id: 'ELIBRA', name: 'ELIBRA' },
  { id: 'LM', name: 'LEAD MENAGER' },


];
