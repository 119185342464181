import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Toolbar, AppBar, Typography, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { analyticActions } from '_actions';
import Filter from './components/Filter';
import CartLeadsInByTime from './components/Carts/CartLeadsInByTime';
import CartLeadsInDepartmentByTime from './components/Carts/CartLeadsInDepartmentByTime';
import LeadsByCountryAndDept from './components/Carts/LeadsByCountryAndDept';
import PerformanceByDepartments from './components/Carts/PerformanceByDepartments';
import ReactApexChart from 'react-apexcharts';
import { TotalRadial } from './components';
import Recap from './components/Recap';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginRight: ({ opened }) => opened ? "400px" : 0,
  },
  spacer: {
    flexGrow: 1
  },
}));

const Analytics2 = () => {

  const [opened, setOpened] = useState(sessionStorage.getItem('analyticsFiltersOpened') === 'true');
  const handleOpenchange = (opened) => {
    setOpened(opened);
  }
  const classes = useStyles({ opened: opened });
  const cartData = useSelector(state => state.analytics.cart2)

  const {loading} = useSelector(state => state.analytics)
  const dispatch = useDispatch();

  const [filters, setFilters] = useState();

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  }

  useEffect(() => {
    const ff = { ...filters };
    if (typeof ff.startDate !== "undefined") {
      // ff.from = ff.startDate === "" ? '1900-01-01' : ff.startDate;
      ff.from = ff.startDate;
    }
    if (typeof ff.endDate !== "undefined") {

      // ff.to = ff.endDate === "" ? '2500-01-01' : ff.endDate;
      ff.to = ff.endDate;
    }
    delete ff.startDate
    delete ff.endDate

    dispatch(analyticActions.getCart2(ff));
  }, [filters, dispatch]);


  const handleExport = () => {
    dispatch(analyticActions._export(filters));
  }

  return (
    <Fragment>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <span className={classes.spacer} />
          <Filter
            filtersChange={handleFiltersChange}
            onOpenChange={handleOpenchange}
            onExportClick={handleExport}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >

          <Grid item lg={12} md={12} xl={12} xs={12}>
            {/* {JSON.stringify(loading)} */}
            <Recap
            loading={loading}
              {...cartData?.totals}
            />
          </Grid>

          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant='h4'>LEADS RECEIVED</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <CartLeadsInByTime
              title="All Leads Received "
              subheader="All incoming leads compared with previous year"
              categories={cartData?.leadsInByTime?.categories}
              series={cartData?.leadsInByTime?.series}
            />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <CartLeadsInDepartmentByTime
              title="Leads Received By Source"
              subheader="All received leads by source"
              categories={cartData?.leadsInSourceByTime?.categories}
              series={cartData?.leadsInSourceByTime?.series}
            />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <CartLeadsInDepartmentByTime
              title="Leads Received By Practices"
              subheader="All incoming leads assigned to a department"
              categories={cartData?.leadsInDepartmentsByTime?.categories}
              series={cartData?.leadsInDepartmentsByTime?.series}
            />
          </Grid>


          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant='h4'>LEADS RECEIVED BY COUNTRY</Typography>
          </Grid>
          {cartData?.leadsInDepartmentsByJurisdiction && cartData.leadsInDepartmentsByJurisdiction.map((v, k) => {
            return (
              <Grid item lg={6} >
                <LeadsByCountryAndDept key={k}
                  categories={v.categories}
                  series={v.series.data}
                  name={v.name}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </Fragment>
  );
};

export { Analytics2 };
