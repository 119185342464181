import { authHeader } from '../helpers';
import handleResponse from './handleResponse';

export const analyticService = {
    getCart2,
    getCart3,
    getCart4,
    getAll,
    getUser,
    exportAnalytics,

    getDepartment,
};

function getCart4(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const params = new URLSearchParams(filters) 

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics4?${params}`, requestOptions).then(handleResponse);
}


function getCart3(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const params = new URLSearchParams(filters) 

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics3?${params}`, requestOptions).then(handleResponse);
}

function getCart2(filters) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const params = new URLSearchParams(filters) 

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics2?${params}`, requestOptions).then(handleResponse);
}

function exportAnalytics(filters) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
  
    return fetch(
      process.env.REACT_APP_API_ENDPOINT + `/api/analytics-export?` +  new URLSearchParams(filters).toString(),
    //   `/api/analytics-export?text=` + encodeURIComponent(text) + `&tag=${tag}&from=${from}&to=${to}&page=${page}&per_page=${per_page}&order_by=${orderBy}&order_direction=${orderDirection}`,
  
      requestOptions
    )
      .then(res => {
  
        const filename = res.headers
          .get('Content-Disposition')
          .split('filename=')[1];
        res.blob().then(blob => {
          var fileURL = window.URL.createObjectURL(blob);
  
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.download = filename;
          fileLink.click();
        });
  
        // return Promise.resolve()
  
      })
  
  }

function getAll(from,to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics?from=${from}&to=${to}`, requestOptions).then(handleResponse);
}

function getUser(from,to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics?gb=user&from=${from}&to=${to}`, requestOptions).then(handleResponse);
}

function getDepartment(from,to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/analytics?gb=department&from=${from}&to=${to}`, requestOptions).then(handleResponse);
}
