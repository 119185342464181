import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import Box from '../Box/Box';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const BoxPanel = props => {
  const { className, primaryBox, topBoxList = [], bottomBoxList = [], ...rest } = props;

  const classes = useStyles();

  return <Grid container direction='row' spacing={2}>

    <Grid lg={2} item >
      <Box
        title={primaryBox ? primaryBox.title : "Title"}
        value={primaryBox ? primaryBox.value : "12345"}
        difference={primaryBox ? primaryBox.difference : "-23%"} />
    </Grid>
    <Grid lg={10} item>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container spacing={2} direction='row' justifyContent='flex-start'>
            {topBoxList.map((i, k) => (
              <Grid item xs={2}>
                <Box
                  bgColor='#c8e6c9'
                  key={k}
                  title={i.title || "-"}
                  value={i.value || "-"}
                  difference={i.difference || "-"} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction='row' justifyContent='flex-start'>
            {bottomBoxList.map((i, k) => (
              <Grid item xs={2}>
                <Box
                  bgColor='#ffcccc'
                  key={k}
                  title={i.title || "-"}
                  value={i.value || "-"}
                  difference={i.difference || "-"} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>


};

BoxPanel.propTypes = {
  className: PropTypes.string,
  primaryBox: PropTypes.object,
  topBoxList: PropTypes.array,
  bottomBoxList: PropTypes.array,
};

export default BoxPanel;
