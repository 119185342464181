/* eslint-disable no-use-before-define */
import React, { Fragment } from 'react';
import ZnSelect from './ZnSelect';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { attachmentActions } from '_actions';

export {
  ZnAttachmentSelect,
  ZnAttachmentValue,
}

const ZnAttachmentSelect = (props) => {

  const {
    id,
    onChange: onChangeParet,
    onKeyPress,
    value,
    error,
    helperText,
    itemsDisabled
  } = props;


  const onChange = (event, value) => {
      if (!value) return;
      
      const attachment = attachments.find(el => el.id === value.code);
      onChangeParet(attachment);
  }

  const [items, setItems] = useState([]);

    
  
  const attachments = useSelector(state => state.attachments.items)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(attachmentActions.getAll());
  }, []);

  useEffect(() => {
    if (!Array.isArray(attachments)) return;
    setItems(attachments.map(x => {
      return { code: x.id, label: x.name };
    }));
  }, [attachments]);

  return (
    <ZnSelect
      id={id}
      label="Attachment"
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={items}
      itemsDisabled={itemsDisabled}
      displayValue={false}
    />
  );
}

const ZnAttachmentValue = (props) => {

  const { value } = props;

  const [label, setLabel] = useState('loading...');

  const attachments = useSelector(state => state.attachments.items)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(attachmentActions.getAll());
  }, []);

  useEffect(() => {

    const id = parseInt(value);
    const attachment = attachments.filter(attachment => { return attachment && attachment.id === id }).shift();
    if (attachment && attachment.name) setLabel(attachment.name);
  }, [attachments, value]);


  return (
    <Fragment>{label}</Fragment>
  );
}




