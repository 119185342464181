import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ReactApexChart from 'react-apexcharts';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: "#ffffff",

  },

}));

const optionsEmpty = {
  chart: {
    type: "radialBar",
  },

  // series: [67],
  colors: ["#eee"],
  plotOptions: {
    radialBar: {
      startAngle: -100,
      endAngle: 100,
      dataLabels: {
        name: {
          offsetY: -10,
          color: "#ccc",
          fontSize: "10px"
        },
        value: {
          color: "#eee",
          show: true,
          formatter: (v)=> '-',
        }
      }
    }
  },
  labels: ['loading']
}

const TotalRadial = props => {
  const { loaded, loading = true, title = '---', value, percent = '--', color = "" } = props;

  const classes = useStyles();

  // const [loaded, setLoaded] = useState(false);
  // useEffect(()=> {
  //   alert(loading);
  //   setLoaded(!loading);
  // }, [loading]);


  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState();

  useEffect(()=> {
    if (!loaded) return;
    const roundedPercValue = Math.round((percent + Number.EPSILON) * 100) / 100;

    

    setSeries([roundedPercValue]);
    setOptions({
      chart: {
        type: "radialBar",
      },
  
      // series: [67],
      colors: [color],
      plotOptions: {
        radialBar: {
          startAngle: -100,
          endAngle: 100,
          dataLabels: {
            name: {
              offsetY: -10,
              color: "#000",
              fontSize: "20px"
            },
            value: {
              color: "#000",
              // fontSize: "30px",
              show: true,
              // formatter: (v)=> 'loading' ,
              formatter: (v)=> (loaded && value) ? value + " leads" : 'loading' ,
            }
          }
        }
      },
      labels: [roundedPercValue + "%"]

      
    });
  }, [loaded, value]);


  return <Card style={{height: "100%"}}>
    <CardContent>
      {/* {JSON.stringify(options)}
      {JSON.stringify(series)}
      
      <hr></hr>*/}
      {/* {JSON.stringify(loading)} 
      {JSON.stringify(loaded)}  */}
      <CardHeader title={title}/>
      { loaded   ? 
      (<ReactApexChart
        type='radialBar'
        options={options}
        series={series} />) 
        : 
        (<ReactApexChart
          type='radialBar'
          options={optionsEmpty}
          series={[100]} />)}
    </CardContent>
  </Card>



};

// TotalRadial.propTypes = {

// };

export default TotalRadial;
