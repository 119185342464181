import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Toolbar, AppBar, Typography, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { analyticActions } from '_actions';
import Filter from './components/Filter';
import CartLeadsInByTime from './components/Carts/CartLeadsInByTime';
import CartLeadsInDepartmentByTime from './components/Carts/CartLeadsInDepartmentByTime';
import LeadsByCountryAndDept from './components/Carts/LeadsByCountryAndDept';
import PerformanceByDepartments from './components/Carts/PerformanceByDepartments';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginRight: ({opened}) => opened ? "400px" : 0 ,
  },
  spacer: {
    flexGrow: 1
  },
}));

const Analytics4 = () => {

  const [opened, setOpened] = useState(sessionStorage.getItem('analyticsFiltersOpened') === 'true');
  const handleOpenchange = (opened) => {
    setOpened(opened);
  }
  const classes = useStyles({opened: opened});
  const cartData = useSelector(state => state.analytics.cart4)
  const dispatch = useDispatch();

  const [filters, setFilters] = useState();

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  }

  useEffect(() => {
    const ff = { ...filters };
    if (typeof ff.startDate !== "undefined") {
      ff.from = ff.startDate === "" ? '1900-01-01' : ff.startDate;
    }
    if (typeof ff.endDate !== "undefined") {

      ff.to = ff.endDate === "" ? '2500-01-01' : ff.endDate;
    }
    delete ff.startDate
    delete ff.endDate

    dispatch(analyticActions.getCart4(ff));
  }, [filters,  dispatch]);


  const handleExport = () => {
    dispatch(analyticActions._export(filters));
  }

  return (
    <Fragment>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <span className={classes.spacer} />
          <Filter 
          filtersChange={handleFiltersChange} 
          onOpenChange={handleOpenchange} 
          onExportClick={handleExport}
            />
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
           {/* <Grid item lg={12} md={12} xl={12} xs={12}>
            <Recap cartData={cartData}/>
          </Grid> */}
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant='h4'>PERFORMANCE</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <PerformanceByDepartments
              title="Practices Performance"
              subheader="performance by departments"
              categories={cartData?.performanceByDepartments?.categories}
              series={cartData?.performanceByDepartments?.series}
            />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <PerformanceByDepartments
              title="Users Performance"
              subheader="performance by users"
              categories={cartData?.performanceByUsers?.categories}
              series={cartData?.performanceByUsers?.series}
            />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export { Analytics4 };
