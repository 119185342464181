import React, { useEffect, Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor:  theme.palette.grey[100],
    
  },
 
}));

const Donut = props => {
  const { series, title, difference, value, bgColor, ...rest  } = props;

  const classes = useStyles();

  return (
    <ReactApexChart
    type="donut"
    options={{
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {chart: {width: 200},legend: {position: 'bottom'}}}]}} 
      series={[44, 55, 41, 17, 15]} 
       />
   
  );
};

Donut.propTypes = {
  className: PropTypes.string
};

export default Donut;
