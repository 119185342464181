import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  LinearProgress
} from '@material-ui/core';
import ZnTextField from 'common/znFields/ZnTextField';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import { ZnDepartmentMultiSelect } from 'common/znFields/ZnDepartmentSelect';
import ZnCountrySelect from 'common/znFields/ZnCountrySelect';
import ZnAttachmentField from 'common/znFields/ZnAttachmentField';
import { DropzoneArea } from 'material-ui-dropzone';

const schema = {
  file: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const defaultFormSchema = {
  loaded: false,
  isValid: false,
  values: {
    file: '',
  },
  touched: {},
  errors: {}
};

const Import = props => {
  const { open, setOpen, actions, reducer } = props;

  const doingAction = reducer.doingAction;
  const successAction = reducer.successAction;
  const actionErrorMsg = reducer.actionErrorMsg;
  const actionError = reducer.actionError;

  const dispatch = useDispatch();

  const handleSubmit = event => {

    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));

    dispatch(actions._import(formState.values));
  };

  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      setOpen(false);
      setFormState(defaultFormSchema);
    }
  };
  const [formState, setFormState] = useState(defaultFormSchema);

  const formValidation = () => {
    if (formState == defaultFormSchema) return
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  };

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {}
    }));
  }, [actionErrorMsg]);

  const hasError = field => {
    if (!formState.errors) return false;
    if (!formState.errors[field]) return false;
    return formState.errors[field] ? true : false;
  };

  const getError = field => {
    if (!formState.errors[field]) return null;
    return formState.errors[field] && formState.errors[field][0];
  };


  const setFiles = (files) => {

    if (formState.touched.file == undefined && files.length == 0) return;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        file: files.shift()
      },
      touched: {
        ...formState.touched,
        file: true
      }
    }));
  }

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title">
          Import Customers
        </DialogTitle>
        {doingAction && (
          <LinearProgress ></LinearProgress>
        )}
        <DialogContent>
          {actionError && (<FormHelperText error>{actionErrorMsg}</FormHelperText>)}
          <DropzoneArea
            dropzoneText="Drag or click"
            // acceptedFiles={['text/csv']}
            filesLimit={1}
            onChange={setFiles}
          ></DropzoneArea>
          {hasError('file') && (
            <FormHelperText error>{getError('file')}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={formState.values.file === ''}  onClick={handleSubmit}>Import</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Import.defaultProps = {
  open: false,
};
export default Import;
