import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {  Drawer,  IconButton } from '@material-ui/core';
import { SearchForm } from '../Topbar';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 400,
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      height: 'calc(100%)'
    }
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
}));




const SidebarGeneral = props => {
  const { children, open, variant, onClose, onClick, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}

      variant="persistent"
    >
       <div className={classes.drawerHeader}>
          {/* <Typography variant="h5" style={{paddingLeft: "15px"}}>Advanced Search</Typography> */}
          <IconButton onClick={onClick}>
            {theme.direction === 'rtl' ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </div>

        {children}
      
    </Drawer>
  );
};

SidebarGeneral.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};



export { SidebarGeneral as default};
