import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, TextField, IconButton, Button, LinearProgress } from '@material-ui/core';
import { ZnDepartmentSelect } from 'common/znFields/ZnDepartmentSelect';
import { ZnSourceSelect } from 'common/znFields/ZnSourceSelect';
import { ZnButton } from 'common/znFields/ZnButton';
import { SidebarGeneral } from 'layouts/Main/components';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear, subMonths, subQuarters } from 'date-fns';
import { format } from 'date-fns';
import { FilterList } from '@material-ui/icons';
import { source } from '_reducers/source.reducer';


const Filter = props => {
  const {
    filtersChange = () => { },
    onOpenChange = () => { },
    onClick,
    className,
    title,
    difference,
    value,
    bgColor,
    onExportClick = () => {},
    ...rest } = props;


  const [filters, setFilters] = useState(sessionStorage.analyticsFilters ?

    JSON.parse(sessionStorage.analyticsFilters) : {
      department_id: "",
      source_id: "",
      startDate: "",
      endDate: "",
    })

  useEffect(() => {
    sessionStorage.setItem('analyticsFilters', JSON.stringify(filters))

    filtersChange(filters);
  }, [filters, filtersChange])


  const [filtersOpened, setFiltersOpened] = useState(sessionStorage.getItem('analyticsFiltersOpened') === 'true')
  useEffect(() => {
    sessionStorage.setItem('analyticsFiltersOpened', filtersOpened.toString())
    onOpenChange && onOpenChange(filtersOpened);
  }, [filtersOpened, onOpenChange])

  const toggleSiderbar = () => {
    setFiltersOpened((filtersOpened) => !filtersOpened);
  }



  const handleDateRangeChange = (dateRange) => {
    setFilters({
      ...filters,
      startDate: dateRange.target?.value?.startDate,
      endDate: dateRange.target?.value?.endDate,
    })
  }

  const handleDateRangeStartChange = (e) => {
    console.log("handleDateRangeStartChange", filters, e.target.value)

    setFilters({
      ...filters,
      startDate: e.target.value
    })
  }

  const handleDateRangeEndChange = (e) => {
    console.log("handleDateRangeEndChange", filters, e.target.value)
    setFilters({
      ...filters,
      endDate: e.target.value
    })
  }

  const handleChangeDepartment = (e, value) => {
    console.log("handleChangeDepartment", value)
    const department_id = value?.code === undefined ? '' : value.code
    setFilters({
      ...filters,
      department_id: department_id
    })
  }

  const handleChangeSource = (event, value) => {
    console.log("handleChangeSource", value)
    const source_id = value?.code === undefined ? '' : value.code
    setFilters({
      ...filters,
      source_id: source_id
    })
    
  };

  const handleMonth = (event) => {
    setFilters({
      ...filters,
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),

    })
  };

  const handleQuarter = (event) => {
    setFilters({
      ...filters,
      startDate: format(startOfQuarter(new Date()), 'yyyy-MM-dd'),
      endDate: format(endOfQuarter(new Date()), 'yyyy-MM-dd'),

    })
  };

  const handleHalfYear = (event) => {
    setFilters({
      ...filters,
      startDate: format(startOfQuarter(subQuarters(new Date(), 6)), 'yyyy-MM-dd'),
      endDate: format(endOfQuarter(new Date()), 'yyyy-MM-dd'),

    })
  };

  const handleYear = (event) => {
    setFilters({
      ...filters,
      startDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
      endDate: format(endOfYear(new Date()), 'yyyy-MM-dd'),

    })
  };

  const handleOnClickReset = () => {
    setFilters({
      department_id: "",
      source_id: "",
      startDate: "",
      endDate: "",
    })
  }

  const doingExport = false; //reducer.doingExport;

  const handleExportClick = () => {
    onExportClick && onExportClick(filters);
  };




  return (
    <>
      <IconButton onClick={toggleSiderbar} ><FilterList /></IconButton>
      <SidebarGeneral onClick={toggleSiderbar} open={filtersOpened}>
        <Box padding={3}>
          <Grid container spacing={3} sx={{ border: "1px solid red" }}>

          <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              
             
              <ZnButton onClick={handleOnClickReset}>reset</ZnButton>
              <ZnButton disabled={doingExport}  onClick={handleExportClick}>export</ZnButton>
             
              <ZnDepartmentSelect
                label="Practices"
                fullWidth={false}
                value={filters.department_id ?? null}
                onChange={handleChangeDepartment} />
              <ZnSourceSelect
                id="source_id"
                label="Source"
                fullWidth={false}
                value={filters.source_id ?? null}
                onChange={handleChangeSource} />
              <TextField
                id="startDate"
                label="From"
                type="date"
                value={filters.startDate}
                deafultValue={filters.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                variant="outlined"
                onChange={handleDateRangeStartChange}
                margin='normal'

              />
              <TextField
                id="endDate"
                label="To"
                type="date"
                value={filters.endDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                variant="outlined"
                onChange={handleDateRangeEndChange}
                margin='normal'
              />
              <Box><ZnButton onClick={handleMonth}>THIS month</ZnButton></Box>
              <Box><ZnButton onClick={handleQuarter}>THIS quarter</ZnButton></Box>
              <Box><ZnButton onClick={handleHalfYear}>THIS half year</ZnButton></Box>
              <Box><ZnButton onClick={handleYear}>THIS year</ZnButton></Box>
            </Grid>
          </Grid>
        </Box>
      </SidebarGeneral>
    </>

  );
};

Filter.propTypes = {
  className: PropTypes.string
};

export default Filter;
