import { authHeader } from '../helpers';
import handleResponse from './handleResponse';

export const customerService = {
  getAll,
  getById,
  exportCustomers,
  _delete,
  doAction,
  _import,
};

function doAction(id, action, data = []) {
  return kick({
    action: action,
    customerId: id,
    data: data
  });
}
function kick(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/customer-kick`,
    requestOptions
  ).then(handleResponse);
}

function exportCustomers(text = '', tag='', from='', to='', page = '', per_page = '', orderBy = '', orderDirection = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + 
    `/api/customer-export?text=` + encodeURIComponent(text) + `&tag=${tag}&from=${from}&to=${to}&page=${page}&per_page=${per_page}&order_by=${orderBy}&order_direction=${orderDirection}`,

    requestOptions
  )
    .then(res => {

      const filename = res.headers
        .get('Content-Disposition')
        .split('filename=')[1];
      res.blob().then(blob => {
        var fileURL = window.URL.createObjectURL(blob);

        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = filename;
        fileLink.click();
      });

      // return Promise.resolve()

    })

}

function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/customer/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getAll(text = '', tag='', source='', from='', to='', page = '', per_page = '', orderBy = '', orderDirection = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT +
    `/api/customer?text=` + encodeURIComponent(text) + `&tag=${tag}&source=${source}&from=${from}&to=${to}&page=${page}&per_page=${per_page}&order_by=${orderBy}&order_direction=${orderDirection}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/customer/${id}`,
    requestOptions
  ).then(handleResponse);
}

function _import(data) {

  const formData = new FormData();
  formData.append('importDataFile', data.file);

  const requestOptions = {
    method: 'POST',
    headers: authHeader(false),
    body: formData,
    redirect: 'follow',
  };

  return fetch(
    process.env.REACT_APP_API_ENDPOINT + `/api/customer-import`,
    requestOptions
  ).then(handleResponse);

}

