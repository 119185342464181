export const analyticConstants = {

    EXPORT_REQUEST: 'ANALYTICS_EXPORT_REQUEST',
    EXPORT_SUCCESS: 'ANALYTICS_EXPORT_SUCCESS',
    EXPORT_FAILURE: 'ANALYTICS_EXPORT_FAILURE',
  

    GETALL_REQUEST: 'ANALYTICS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ANALYTICS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ANALYTICS_GETALL_FAILURE',

    GETUSER_REQUEST: 'ANALYTICS_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'ANALYTICS_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'ANALYTICS_GETUSER_FAILURE',

    GETDEPARTMENT_REQUEST: 'ANALYTICS_GETDEPARTMENT_REQUEST',
    GETDEPARTMENT_SUCCESS: 'ANALYTICS_GETDEPARTMENT_SUCCESS',
    GETDEPARTMENT_FAILURE: 'ANALYTICS_GETDEPARTMENT_FAILURE',

    GET_CART_2_REQUEST: 'ANALYTICS_GET_CART_2_REQUEST',
    GET_CART_2_SUCCESS: 'ANALYTICS_GET_CART_2_SUCCESS',
    GET_CART_2_FAILURE: 'ANALYTICS_GET_CART_2_FAILURE',

    GET_CART_3_REQUEST: 'ANALYTICS_GET_CART_3_REQUEST',
    GET_CART_3_SUCCESS: 'ANALYTICS_GET_CART_3_SUCCESS',
    GET_CART_3_FAILURE: 'ANALYTICS_GET_CART_3_FAILURE',

    GET_CART_4_REQUEST: 'ANALYTICS_GET_CART_4_REQUEST',
    GET_CART_4_SUCCESS: 'ANALYTICS_GET_CART_4_SUCCESS',
    GET_CART_4_FAILURE: 'ANALYTICS_GET_CART_4_FAILURE',
};
