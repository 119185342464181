/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment } from 'react';
import ZnSelect from './ZnSelect';
import { Select, MenuItem, FormControl, FormLabel, FormGroup, Checkbox, FormHelperText, FormControlLabel, InputLabel, Button } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { configActions } from '_actions/config.actions';
import { leadActions, messageModelActions, attachmentActions, userActions } from '_actions';
import theme from 'theme';

export {
  ZnDepartmentSelect,
  ZnDepartmentFormSelect,
  ZnDepartmentSimpleSelect,
  ZnDepartmentValue,
  ZnDepartmentMultiSelect,
}


const simpleSelectUseStyles = makeStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    //color: 'white',
  },
  select: {
    //color: 'inerith'
  },
  icon: {
    //color: 'white'
  },
})

const ZnDepartmentMultiSelect = (props) => {

  const classes = simpleSelectUseStyles();

  const {
    id,
    label,
    onChange,
    onKeyPress,
    values,
    error,
    helperText
  } = props;

  const [checked, setChecked] = useState([]);

  const isChecked = (code) => {
    return checked.includes(parseInt(code));
  }

  useEffect(() => {
    setChecked(values);
  }, [values]);



  const getDepartments = () => {
    return departments.map((dep) => {
      dep.checked = isChecked(dep);
      return dep;
    });
  }

  const handleChange = (e) => {
    const code = parseInt(e.target.value);
    if (isChecked(code)) {
      var values = checked.filter((value) => { return value !== code; });

    } else {
      var values = [...checked, code];
    }

    setChecked(values);
    onChange(values);

  }

  return (
    <FormControl margin='normal' className={classes.formControl}>
      <FormLabel component="legend">Departments</FormLabel>
      <FormGroup style={{ padding: "5px" }}>
        {getDepartments().map((dep, k) => (
          <FormControlLabel key={k}
            control={<Checkbox
              // checked={dep.checked}
              checked={isChecked(dep.code)}
              value={dep.code}
              onChange={handleChange}
              name="departments" />} label={dep.label}
          />
        ))}
      </FormGroup>

    </FormControl>
  );


}

/*
const ZnDepartmentSimpleSelect = ({ onChange, value, fullWidth = true }) => {
  const classes = simpleSelectUseStyles();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

    const reset = () => {
    setAge(null)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      <Button onClick={reset}>res</Button>
      </div>
  )
}
*/


const ZnDepartmentSimpleSelect = ({ label, onChange, value, fullWidth = true, withPlaceholder=false}) => {

  const classes = simpleSelectUseStyles();

  const [departmentId, setDepartmentId] = useState('')
  const handleChange = e => {
    setDepartmentId(e.target.value)
  }

  useEffect(() => {
    if (departmentId == value) return
    onChange && onChange(departmentId)
  }, [departmentId])

  useEffect(() => {
    if (value == null) {
      setDepartmentId('')
      return
    }
    if (value === undefined) return
    if (value == departmentId) return
    setDepartmentId(value)

  }, [value])

  return (
    <>
      <FormControl fullWidth={fullWidth} className={withPlaceholder && classes.formControl}>
        {withPlaceholder && (<InputLabel id="department-simple-select-label">Department</InputLabel>)}
        <Select
          value={departmentId}
          onChange={handleChange}
          fullWidth={fullWidth}
          labelId="department-simple-select-label"
          displayEmpty
        >
          <MenuItem value="">All {label ?? 'department'}</MenuItem>
          {departments.map((dep, k) => {
            return (
              <MenuItem key={k} value={dep.code}>{dep.label}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>

  );
}

const ZnDepartmentSelect = (props) => {

  const {
    id,
    label,
    onChange,
    onKeyPress,
    value,
    error,
    helperText
  } = props;

  return (
    <ZnSelect
      id={id}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={departments}
    />
  );
}

const ZnDepartmentValue = (props) => {

  const { value } = props;

  const [label, setLabel] = useState(null);

  const getLabel = (value) => {
    const val = '' + value;
    const code = departments.filter(item => { return item && item.code === val }).shift();
    if (code && code.label) return code.label;
  }

  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((item, k) => (
          <Fragment key={k}>{(k ? ', ' : '') + getLabel(item)}</Fragment>

        ))}
      </div>
    );

  }

  return (
    <Fragment>{getLabel(value)}</Fragment>
  );
}

const departments = [

  { code: "1", label: 'Corporate Service Inbound' },
  { code: "2", label: 'Corporate Service Outbound' },
  { code: "3", label: 'Labour & Payroll' },
  { code: "4", label: 'Tax & Accounting' },
  { code: "5", label: 'Immigration' },
  { code: "6", label: 'Financial Services Regulatory & Fintech' },
  { code: "60", label: 'Litigation' },
  { code: "100", label: 'Others' }

];

const ZnDepartmentFormSelect = ({ defaultValue, onChange }) => {

  const classes = simpleSelectUseStyles();

  const [selected, setSelected] = useState(0)

  const handleChange = event => {
    setSelected(event.target.value)
  };

  useEffect(() => {
    onChange && onChange({
      target: {
        value: selected
      }
    })
  }, [selected])

  useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])


  return (

    <Select
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
      }}
      value={selected}
      onChange={handleChange}
    >
      <MenuItem value="0">All department</MenuItem>
      {departments.map((dep, k) => {
        return (
          <MenuItem key={k} value={dep.code}>{dep.label}</MenuItem>
        );
      })}
    </Select>

  );
}
